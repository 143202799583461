<template>
    <geek-attest v-if="userInfo.info.user_type==1" />
    <employer-attest v-else />
</template>
<script>
import { mapState } from "vuex";
import geekAttest from "./geek/index.vue";
import employerAttest from "./employer/index.vue";
export default {
    components: {
        geekAttest,
        employerAttest
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(["userInfo"])
    },
    created() {
        if (this.userInfo.info.attestation == 3) {//审核未通过跳转到编辑页面
            this.$router.push('/user/attest/edit');
        }
    }
};
</script>