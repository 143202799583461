<template>
    <!-- 填写基本信息  -->
    <div class="attestation">
        <div class="white-bg">
            <div class="form-panner base-info-panner">
                <header class="panner-hd">
                    <h3 class="main-tit">选择雇主认证身份</h3>
                </header>
                <div class="form-panner-bd step0-info">
                    <div class="selitem-inner employee-selitem-inner">
                        <el-row
                            :gutter="20"
                            style="overflow:hidden"
                        >
                            <el-col
                                :span="8"
                                :push="4"
                            >
                                <article
                                    class="sel-role-item person"
                                    title="请点击选择入驻身份"
                                >
                                    <div class="default-cont">
                                        <i
                                            class="jk-normal rol-ico"
                                            :style="{backgroundImage:'url('+$img.gerenguzhu_normal+')'}"
                                        ></i>
                                        <p class="role-name">个人雇主</p>
                                    </div>
                                    <div class="hover-cont">
                                        <h3 class="role-name">个人雇主</h3>
                                        <div class="rol-desc">个人雇主需提交个人实名信息进行认证。</div>
                                    </div>
                                    <el-radio
                                        v-model="userType"
                                        label="1"
                                        class="check-radio"
                                    ></el-radio>
                                    <transition name="el-fade-in">
                                        <div
                                            class="checked-cont"
                                            v-if="userType==1"
                                        >
                                            <i
                                                class="jk-active"
                                                :style="{backgroundImage:'url('+$img.gerenguzhu_click+')'}"
                                            ></i>
                                            <p class="role-name">个人雇主</p>
                                            <p class="desc">个人雇主需提交个人实名信息进行认证。</p>
                                            <p class="role-check-icon-wraper">
                                                <i class="jk-icon-xuanzhongshenfen check-icon"></i>
                                            </p>
                                        </div>
                                    </transition>
                                </article>
                            </el-col>
                            <el-col
                                :span="8"
                                :push="4"
                            >
                                <article
                                    class="sel-role-item gognzuoshi"
                                    title="请点击选择入驻身份"
                                >
                                    <div class="default-cont">
                                        <i
                                            class="jk-normal rol-ico"
                                            :style="{backgroundImage:'url('+$img.danweiguzhu_normal+')'}"
                                        ></i>
                                        <p class="role-name">单位级雇主</p>
                                    </div>
                                    <div class="hover-cont">
                                        <h3 class="role-name">单位级雇主</h3>
                                        <div class="rol-desc">单位级雇主适合政府机关、事业单位、民营企业、公司，认证时需要填写相关单位信息。</div>
                                    </div>
                                    <el-radio
                                        v-model="userType"
                                        label="2"
                                        class="check-radio"
                                    ></el-radio>
                                    <transition name="el-fade-in">
                                        <div
                                            class="checked-cont"
                                            v-if="userType==2"
                                        >
                                            <i
                                                class="jk-active"
                                                :style="{backgroundImage:'url('+$img.danweiguzhu_click+')'}"
                                            ></i>
                                            <p class="role-name">单位级雇主</p>
                                            <p class="desc">单位级雇主适合政府机关、事业单位、民营企业、公司，认证时需要填写相关单位信息。</p>
                                            <p class="role-check-icon-wraper">
                                                <i class="jk-icon-xuanzhongshenfen check-icon"></i>
                                            </p>
                                        </div>
                                    </transition>
                                </article>
                            </el-col>
                            <el-col
                                :span="24"
                                class="next-btn-wraper"
                            >
                                <el-button
                                    :disabled="!userType"
                                    type="primary"
                                    class="next-btn"
                                    @click="routerTo"
                                >下一步</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "ucIndex",
    data() {
        return {
            userType: ""
        };
    },
    methods: {
        routerTo() {
            this.$router.push({
                name: "userAttestEdit",
                query: { type: this.userType }
            });
        }
    },
};
</script>
<style lang="less" scoped>
@import "../../../../styles/ucattestation.less";
.selitem-inner {
    padding: 60px 0 0;
}
.employee-selitem-inner .jk-active {
    margin-top: 50px;
    background-size: contain;
}
.checked-cont .desc {
    color: rgba(255, 255, 255, 0.5);
}
</style>


