<template>
    <!-- 填写基本信息  -->
    <div class="attestation">
        <div class="white-bg">
            <div class="form-panner base-info-panner">
                <header class="panner-hd">
                    <h3 class="main-tit">选择入驻身份</h3>
                </header>
                <div class="form-panner-bd step0-info">
                    <div class="selitem-inner">
                        <el-row
                            :gutter="20"
                            style="overflow:hidden"
                        >
                            <el-col :span="8">
                                <article
                                    class="sel-role-item person"
                                    title="请点击选择入驻身份"
                                >
                                    <div class="default-cont">
                                        <i
                                            class="jk-normal rol-ico"
                                            :style="{backgroundImage:'url('+$img.jk_icon_gerengongchengjikenormal+')'}"
                                        ></i>
                                        <p class="role-name">个人工程极客</p>
                                    </div>
                                    <div class="hover-cont">
                                        <h3 class="role-name">个人工程极客</h3>
                                        <div class="rol-desc">个人工程极客是具有专业能力及多年行业从业经验的优秀人才。入驻时需提交个人实名信息进行认证，我们建议您尽量完善地填写个人介绍，资格证书及过往业绩，以便您在入驻后获得更多的任务机会。</div>
                                    </div>
                                    <el-radio
                                        v-model="userType"
                                        label="1"
                                        class="check-radio"
                                    ></el-radio>
                                    <transition name="el-fade-in">
                                        <div
                                            class="checked-cont"
                                            v-if="userType==1"
                                        >
                                            <i
                                                class="jk-active"
                                                :style="{backgroundImage:'url('+$img.jk_icon_gerengongchengjikeclick+')'}"
                                            ></i>
                                            <p class="role-name">个人工程极客</p>
                                            <p class="role-check-icon-wraper">
                                                <i class="jk-icon-xuanzhongshenfen check-icon"></i>
                                            </p>
                                        </div>
                                    </transition>
                                </article>
                            </el-col>

                            <el-col :span="8">
                                <article
                                    class="sel-role-item gognzuoshi"
                                    title="请点击选择入驻身份"
                                >
                                    <div class="default-cont">
                                        <i
                                            class="jk-normal rol-ico"
                                            :style="{backgroundImage:'url('+$img.jk_icon_gongzuoshigongchengjikenormal+')'}"
                                        ></i>
                                        <p class="role-name">工作室工程极客</p>
                                    </div>
                                    <div class="hover-cont">
                                        <h3 class="role-name">工作室工程极客</h3>
                                        <div class="rol-desc">工作室工程极客是由3人或3人以上组成的团队，工作室负责人必须为拥有执业资格证书的国家注册人员，建议此类工程极客完善填写团队信息及业绩信息，以更好地提高自身竞争力。</div>
                                    </div>
                                    <el-radio
                                        v-model="userType"
                                        label="2"
                                        class="check-radio"
                                    ></el-radio>
                                    <transition name="el-fade-in">
                                        <div
                                            class="checked-cont"
                                            v-if="userType==2"
                                        >
                                            <i
                                                class="jk-active"
                                                :style="{backgroundImage:'url('+$img.jk_icon_gongzuoshigongchengjikeclick+')'}"
                                            ></i>
                                            <p class="role-name">工作室工程极客</p>
                                            <p class="role-check-icon-wraper">
                                                <i class="jk-icon-xuanzhongshenfen check-icon"></i>
                                            </p>
                                        </div>
                                    </transition>
                                </article>
                            </el-col>
                            <el-col :span="8">
                                <article
                                    class="sel-role-item danwei"
                                    title="请点击选择入驻身份"
                                >
                                    <div class="default-cont">
                                        <i
                                            class="jk-normal rol-ico"
                                            :style="{backgroundImage:'url('+$img.jk_icon_danweijigongchengjikenormal+')'}"
                                        ></i>
                                        <p class="role-name">单位工程极客</p>
                                    </div>
                                    <div class="hover-cont">
                                        <h3 class="role-name">单位工程极客</h3>
                                        <div class="rol-desc">单位级工程极客适合企业及事业单位，需要填写相关单位信息，我们建议单位级工程极客完善单位的资质，业绩及团队信息，以获得更多的项目机会。</div>
                                    </div>
                                    <el-radio
                                        v-model="userType"
                                        label="3"
                                        class="check-radio"
                                    ></el-radio>
                                    <transition name="el-fade-in">
                                        <div
                                            class="checked-cont"
                                            v-if="userType==3"
                                        >
                                            <i
                                                class="jk-active"
                                                :style="{backgroundImage:'url('+$img.jk_icon_danweijigongchengjikeclick+')'}"
                                            ></i>
                                            <p class="role-name">单位工程极客</p>
                                            <p class="role-check-icon-wraper">
                                                <i class="jk-icon-xuanzhongshenfen check-icon"></i>
                                            </p>
                                        </div>
                                    </transition>
                                </article>
                            </el-col>
                            <el-col
                                :span="24"
                                class="next-btn-wraper"
                            >
                                <el-button
                                    :disabled="!userType"
                                    type="primary"
                                    class="next-btn"
                                    @click="routerTo"
                                >下一步</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "ucIndex",
    data() {
        return {
            userType: ""
        };
    },
    methods: {
        routerTo() {
            this.$router.push({ //跳转到编辑页面
                name: "userAttestEdit",
                query: { type: this.userType }
            });
        }
    },

    computed: {
        ...mapState(["userInfo"])
    },
};
</script>
<style lang="less" scoped>
@import "../../../../styles/ucattestation.less";
.selitem-inner {
    padding: 60px 0 0;
}
</style>


